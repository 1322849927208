<script>
export default {
  props: {
    vnode: [Array, String, Object, Number],
  },
  render() {
    if (this.vnode === undefined) {
      return
    }
    if (typeof this.vode === 'object') {
      return this.vnode
    }
    return <span render-added>{this.vnode}</span>
  },
}
</script>
