import Vue from 'vue'
import VueRouter from 'vue-router'
import moment from 'moment'
import 'moment/locale/zh-cn'
import NProgress from 'nprogress' // progress bar

import '@/core/component_use'
import App from './App.vue'

import '@/assets/css/index.less'
import store from './store'
import '@/core/directives'
import router from './router'

import axiosInstance from './plugins/axios'
import permissionWrapper from './plugins/permission'
import popErrorPageWrapper from './plugins/popErrorPage'
import keepAliveCtrlWrapper from './plugins/keepAliveCtrl'
import './mock'
moment.locale('zh-cn')

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.prototype.$progress = NProgress
Vue.prototype.$axios = axiosInstance
Vue.prototype.$popErrorPage = popErrorPageWrapper({
  store,
  router,
})
Vue.prototype.$routex = keepAliveCtrlWrapper({
  store,
  router,
})

permissionWrapper({
  store,
  router,
  app: Vue.prototype,
})

export const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
