import Vue from 'vue'
import PopErrorPage from '@/components/PopErrorPage/index.vue'
export default function(context) {
  const { router, store } = context
  let prevLocation
  const PopErrorPageConstructor = Vue.extend(PopErrorPage)
  const vPopErrorPage = new PopErrorPageConstructor({
    store: store
  }).$mount()
  const instanceEl = vPopErrorPage.$el
  router.afterEach((to, from, next) => {
    setTimeout(() => {
      if (prevLocation !== window.location.href) {
        vPopErrorPage.hide()
      }
    })
  })
  return function(msg) {
    prevLocation = window.location.href
    if (!document.body.contains(instanceEl)) {
      document.body.appendChild(instanceEl)
    }
    vPopErrorPage.show(msg)
  }
}
