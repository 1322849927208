<template>
  <keep-alive :include="keepAliveInclude">
    <component :is="keepAliveCompName" v-if="loaded"></component>
    <router-view v-if="!keepAliveCompName && loaded"></router-view>
  </keep-alive>
</template>

<script>
export default {
  props: {
    shouldKeepAlive: {
      type: Function,
      default() {
        return this.$route.meta.keepAlive
      },
    },
  },
  computed: {
    loaded() {
      return this.$store.state.app.loaded
    },
    keepAliveInclude() {
      const { keepAliveInclude, keepAliveRouteMap } = this.$store.state.app
      return keepAliveInclude.map(path => {
        return keepAliveRouteMap[path]
      })
    },
    keepAliveCompName() {
      const { keepAliveRouteMap } = this.$store.state.app
      if (this.shouldKeepAlive()) {
        return keepAliveRouteMap[this.$route.fullPath]
      }
      return ''
    },
  },
  watch: {
    $route: {
      handler() {
        if (this.shouldKeepAlive()) {
          this.$store.dispatch('SetKeepAlive', this.$route.fullPath)
        }
      },
      immediate: true,
    },
  },
}
</script>
