/* eslint-disable */
/**
 * 该文件是为了按需加载，剔除掉了一些不需要的框架组件。
 * 减少了编译支持库包大小
 *
 * 当需要更多组件依赖时，在该文件加入即可
 */
import Vue from 'vue'
import {
  ConfigProvider,
  Layout,
  Input,
  InputNumber,
  Button,
  Switch,
  Radio,
  Checkbox,
  Select,
  Card,
  Form,
  FormModel,
  Row,
  Col,
  Modal,
  Table,
  Tabs,
  Icon,
  Badge,
  Popover,
  Dropdown,
  Avatar,
  Breadcrumb,
  Spin,
  Menu,
  Tooltip,
  Alert,
  Tag,
  Divider,
  DatePicker,
  TimePicker,
  Upload,
  Progress,
  Pagination,
  Popconfirm,
  message,
  notification,
  TreeSelect,
  Tree,
  Transfer,
  // Drawer,
  // Skeleton,
  // Steps,
  List,
  Timeline,
  // Cascader,

  // Slider,
  // Empty,
  // Descriptions,
  // AutoComplete,
  // Rate,
  // Calendar,
} from 'ant-design-vue'

import { Fragment } from 'vue-frag'

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import VueClipboard from 'vue-clipboard2'
// import VueCropper from 'vue-cropper'
import AutoImg from '@/components/AppAutoImg'
import PictureCardUpload from '@/components/pictureCardUpload/PictureCardUpload'
import BasePagination from '@/components/BasePagination'
import RenderDom from '@/components/RenderDom'
import NoKeepAlive from '@/components/NoKeepAlive'

Vue.use(VueViewer)
Vue.use(VueClipboard)
Vue.component('fragment', Fragment)

Vue.use(ConfigProvider)
Vue.use(Layout)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Button)
Vue.use(Switch)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(Select)
Vue.use(Card)
Vue.use(Form)
Vue.use(FormModel)
Vue.use(Row)
Vue.use(Col)
Vue.use(Modal)
Vue.use(Table)
Vue.use(Tabs)
Vue.use(Icon)
Vue.use(Badge)
Vue.use(Popover)
Vue.use(Dropdown)

Vue.use(Avatar)
Vue.use(Breadcrumb)

Vue.use(Spin)
Vue.use(Menu)

Vue.use(Tooltip)
Vue.use(Alert)
Vue.use(Tag)
Vue.use(Divider)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(Upload)
Vue.use(Progress)
Vue.use(Popconfirm)

Vue.use(Pagination)
Vue.use(notification)
Vue.use(TreeSelect)
Vue.use(Tree)
Vue.use(Transfer)
// Vue.use(VueCropper)
Vue.use(List)
// Vue.use(Steps)
// Vue.use(Drawer)
// Vue.use(Skeleton)
Vue.use(Timeline)
// Vue.use(Cascader)

// Vue.use(Slider)
// Vue.use(Empty)
// Vue.use(Anchor)
// Vue.use(Descriptions)
// Vue.use(AutoComplete)
// Vue.use(Calendar)

// Vue.use(Rate)

Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning

const AppIcon = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2826586_je6lq9z3ntt.js',
})
Vue.component('auto-img', AutoImg)
Vue.component('picture-card-upload', PictureCardUpload)
Vue.component('base-pagination', BasePagination)
Vue.component('app-icon', AppIcon)
Vue.component('render-dom', RenderDom)
Vue.component('no-keep-alive', NoKeepAlive)
