import { setDocumentTitle } from '@/utils/domUtil'
import { is401 } from '@/plugins/axios/configFns'
import { constantRouterMap } from '@/routes'
import treeUtils from '@/utils/treeUtils'
const whiteList = [] // no redirect whitelist
treeUtils.forEach(constantRouterMap, (item) => {
  const whiteMenu = item.redirect || item.path
  if (!whiteList.includes(whiteMenu)) {
    whiteList.push(whiteMenu)
  }
})
export default function (context) {
  const { app, router, store } = context
  // eslint-disable-next-line
  const { $progress, $popErrorPage } = app

  router.beforeEach(async (to, from, next) => {
    $progress.start() // start progress bar
    to.meta && typeof to.meta.title !== 'undefined' && setDocumentTitle(to.meta.title)

    if (whiteList.includes(to.path)) {
      // 在免登录白名单，直接进入
      next()
    } else {
      if (store.state.user.auth.accessToken) {
        /* has token */

        if (store.state.permission.asyncRouterMap.length === 0) {
          try {
            const { roles } = await store.dispatch('GetInfo')
            const routes = await store.dispatch('GenerateRoutes', { menus: roles.permission.menus })
            // 根据roles权限生成可访问的路由表
            // 动态添加可访问路由表
            router.addRoutes(routes)
            next({ ...to, replace: true })
          } catch (e) {
            console.log('[GetInfo fail]', e)
            if (is401(e)) {
              // 重定向异常，这里不需要处理，全局请求封装有处理
              next()
            } else {
              // 注意顺序，先跳转页面，然后再覆盖页面弹出错误页
              next()
              $popErrorPage(e.message)
            }
          }
        } else {
          next()
        }
      } else {
        next({ path: '/user/login', query: { redirect: to.fullPath }, replace: true })
        $progress.done() // if current page is login will not trigger afterEach hook, so manually handle it
      }
    }
  })
  router.afterEach(() => {
    $progress.done() // finish progress bar
  })
}
