<script lang="jsx">
import treeUtils from '@/utils/treeUtils'
export default {
  name: 'SideMenu',
  props: {
    menus: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeKeys: [],
      openKeys: [],
    }
  },
  computed: {
    level2Menus() {
      const level2Menus = this.menus[this.activeLevel1MenusIndex]
      return (level2Menus && level2Menus.children) || []
    },
    activeLevel1MenusIndex() {
      let index = -1
      for (let i = 0; i < this.menus.length; i++) {
        if (this.isMenuActive(this.menus[i])) {
          index = i
          break
        }
      }
      return index
    },
  },
  mounted() {
    this.updateMenu()
  },
  watch: {
    $route: function () {
      this.updateMenu()
    },
  },
  methods: {
    isMenuActive(item) {
      let flag = false
      const path = this.$route.path
      function wlk(menu) {
        if (path === menu.path) {
          flag = true
        } else {
          if (menu.children) {
            menu.children.map((m) => {
              wlk(m)
            })
          }
        }
      }
      wlk(item)
      return flag
    },
    updateMenu() {
      const activeRoutes = treeUtils.findAllParents(this.menus, (m) => {
        return m.path === this.$route.path
      })
      activeRoutes.shift()
      const routes = []
      activeRoutes.map((route) => {
        routes.push({
          meta: route.meta,
          path: route.path,
        })
      })
      routes.push({
        path: this.$route.path,
        meta: this.$route.meta,
      })
      this.activeKeys = routes.map((route) => {
        return route.path
      })
    },

    renderMenuItem(menu) {
      const isActive = this.activeKeys.includes(menu.path)
      const onClick = (e) => {
        this.$routex.reloadNext()
        this.$router.push(menu.path)
        e.preventDefault()
      }
      if (!menu.meta.hidden) {
        return (
          <div class={['menu-item', isActive ? 'active' : '']}>
            <span onClick={onClick}>
              {this.renderIcon(menu.meta.icon)}
              <span class="title-text">{menu.meta.title}</span>
            </span>
          </div>
        )
      }
    },
    renderRootMenuItem(menu) {
      let isActive = this.activeKeys.includes(menu.path)

      const affixMenuItems = []

      if (menu.children) {
        this.activeKeys.map((key) => {
          for (let i = 0; i < menu.children.length; i++) {
            if (key.startsWith(menu.children[i].path)) {
              isActive = true
            }
          }
        })
        for (let i = 0; i < menu.children.length; i++) {
          if (!menu.children[i].meta.hidden) {
            affixMenuItems.push(this.renderMenuItem(menu.children[i]))
          }
        }
      }
      const onClick = (e) => {
        this.$routex.reloadNext()
        this.$router.push(menu.path)
        e.preventDefault()
      }
      if (!menu.meta.hidden) {
        return (
          <div
            class={['menu-level-0', isActive ? 'active' : '', affixMenuItems.length ? 'has-children' : '']}
            ref={menu.path}
          >
            <div
              class="title"
              onClick={(e) => {
                console.log('l2menu click')
                if (!isActive) {
                  onClick(e)
                }
              }}
            >
              {this.renderIcon(menu.meta.icon)}
              <span class="title-text">{menu.meta.title}</span>

              {!!affixMenuItems.length && (
                <span class="right-icon">
                  <a-icon type={isActive ? 'up' : 'down'}></a-icon>
                </span>
              )}
            </div>
            {affixMenuItems.length > 0 && <div class="affix-menu-list">{affixMenuItems}</div>}
          </div>
        )
      }
    },
    generateMenuTree(menu) {
      if (!menu.length) {
        return []
      }
      const rootMenuTree = []
      menu.map((m) => {
        rootMenuTree.push(this.renderRootMenuItem(m))
      })
      return rootMenuTree
    },
    renderIcon(icon) {
      if (!icon || icon === 'none') {
        return null
      }
      const props = {}
      typeof icon === 'object' ? (props.component = icon) : (props.type = icon)
      return <app-icon {...{ props }} />
    },
  },

  render() {
    const menuTree = this.generateMenuTree(this.level2Menus)
    if (!menuTree.length) {
      return null
    }
    return (
      <div class="site-menu" ref="menu">
        <div class="menu-logo">
          <img src={''} />
        </div>
        {menuTree}
      </div>
    )
  },
}
</script>
<style lang="less">
.site-menu {
  overflow: auto;
  background: #fff;
  width: 300px;
  box-shadow: 5px 0px 10px 0px rgba(153, 153, 153, 0.3);
  position: relative;
  z-index: 2;
  .menu-logo {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 200px;
    }
  }
  .menu-level-0 {
    color: #3a3a3a;
    position: relative;
    &.active .title {
      background: @primary-color;
      color: #fff;
    }
    .title {
      padding-left: 52px;
      font-size: 16px;
      line-height: 56px;
      position: relative;
      width: 270px;
      height: 56px;
      border-radius: 0px 28px 28px 0px;

      cursor: pointer;
      .title-text {
        margin-left: 12px;
      }
      // &:after {
      //   content: '';
      //   position: absolute;
      //   right: 30px;
      //   top: 0;
      //   bottom: 0;
      //   margin: auto;
      //   width: 6px;
      //   height: 10px;
      //   background: url('~@/assets/images/arrow-0.png');
      //   background-size: cover;
      // }
    }
    &.has-children .title {
      border-radius: 0px 28px 0 0px;
    }
    &.active .affix-menu-list {
      max-height: unset;
    }
    .right-icon {
      position: absolute;
      right: 30px;
    }
    .affix-menu-list {
      padding-left: 80px;
      width: 270px;
      max-height: 0;
      background: #fff2f2;
      border-radius: 0px 0px 28px 0px;
      overflow: hidden;
      .menu-item {
        font-size: 16px;
        line-height: 56px;
        height: 56px;
        position: relative;
        color: #666;
        cursor: pointer;
        .title-text {
          margin-left: 12px;
        }
        > span {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
        &.active {
          color: @primary-color;
        }
        &:hover {
          color: @primary-color;
        }
      }
    }
  }
  > .menu-item {
    padding: 0 30px;
    line-height: 55px;
  }
}
</style>
