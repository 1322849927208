<template>
  <a-layout :class="['layout h-full', device]">
    <a-layout-content class="flex h-full">
      <side-s-menu :menus="menus"></side-s-menu>

      <div class="content-main-holder">
        <transition name="page-transition">
          <KeepAliveRouterView />
        </transition>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { mapState } from 'vuex'

import KeepAliveRouterView from '@/components/KeepAliveRouterView'
import SideSMenu from '@/components/SideSMenu'

export default {
  name: 'BasicLayout',
  components: {
    KeepAliveRouterView,
    SideSMenu,
  },
  data() {
    return {
      menus: [],
    }
  },
  computed: {
    ...mapState({
      permissionMenus: (state) => state.permission.menus,
    }),
  },
  created() {
    this.menus = this.permissionMenus
  },
  mounted() {},
}
</script>

<style lang="less">
/*
 * The following styles are auto-applied to elements with
 * transition="page-transition" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the page transition by editing
 * these styles.
 */
.page-transition-enter {
  opacity: 0;
}
.page-transition-enter-active {
  transition: opacity 0.8s;
}
</style>
