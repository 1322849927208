// eslint-disable-next-line
import { BasicLayout, BlankLayout, RouterView } from '@/layouts'

export const asyncRouterMapRoot = [
  {
    path: '/m',
    component: BasicLayout,
    // 动态添加
    children: [],
  },
  {
    path: '/b',
    component: RouterView,
    // 动态添加
    children: [],
  },
]
// permissionMenuRoutes为用户权限菜单，用户有权限，才会展示其中的某些菜单
// 路由path是唯一值，不能重复，menu视图建议/m开头
// NOTE 目录只有本身路由，可简写（不用写RouterView重定向到下级）
export const permissionMenuRoutes = [
  {
    path: '/m/home',
    meta: {
      title: '首页',
    },
    component: () => import('@/pages/home/Home'),
  },
]

// defaultMenus数据结构同权限路由组装以后的结果permissionMenus(->见permission.js)，这里可直接添加到constantRouterMap
// 路由path命名需要唯一！带redirect的中转路由，只要关注redirect就好了。
export const processedDefaultMenus = [
  // {
  //   path: '/m/d3',
  //   meta: {
  //     title: '工作台'
  //   },
  //   component: BasicLayout,
  //   redirect: '/m/workplace',
  //   children: [
  //     {
  //       path: '/m/workplace',
  //       meta: {
  //         title: '工作台',
  //         hidden: true
  //       },
  //       component: () => import('@/pages/Workplace')
  //     }
  //   ]
  // },
  // {
  //   path: '/m/d1',
  //   meta: {
  //     title: '静态目录1'
  //   },
  //   component: BasicLayout,
  //   redirect: '/m/d1/l1',
  //   children: [
  //     {
  //       path: '/m/d1/l1',
  //       meta: {
  //         title: '系统管理'
  //       },
  //       component: RouterView,
  //       redirect: '/m/home',
  //       children: [
  //         {
  //           path: '/m/home',
  //           meta: {
  //             title: '首页'
  //           },
  //           component: () => import('@/pages/Home')
  //         }
  //         // {
  //         //   path: '/m/menuManage',
  //         //   component: () => import('@/pages/MenuManage'),
  //         //   meta: {
  //         //     title: '菜单管理'
  //         //   }
  //         // },
  //         // {
  //         //   path: '/m/roleManage',
  //         //   component: () => import('@/pages/roleManage/RoleManage'),
  //         //   meta: {
  //         //     title: '角色管理'
  //         //   }
  //         // }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   path: '/m/d2',
  //   meta: {
  //     title: '静态目录2'
  //   },
  //   component: BasicLayout,
  //   redirect: '/m/d2/l1',
  //   children: [
  //     {
  //       path: '/m/d2/l1',
  //       meta: {
  //         title: '简单模块'
  //         // hideChildrenInMenu: true
  //       },
  //       component: RouterView,
  //       redirect: '/m/simplePage/main',
  //       children: [
  //         {
  //           path: '/m/simplePage/main',
  //           meta: {
  //             title: '主模块（列表页）'
  //           },
  //           component: RouterView,
  //           redirect: '/m/simplePage/main/list',
  //           children: [
  //             {
  //               path: '/m/simplePage/main/list',
  //               meta: {
  //                 title: '列表页',
  //                 hidden: true
  //               },
  //               component: () => import('@/pages/simplePage/main/List')
  //             },
  //             {
  //               path: '/m/simplePage/main/detail',
  //               meta: {
  //                 title: '详情页',
  //                 hidden: true
  //               },
  //               component: () => import('@/pages/simplePage/main/Detail')
  //             }
  //           ]
  //         },
  //         {
  //           path: '/m/simplePage/overview',
  //           meta: {
  //             title: '其他模块'
  //           },
  //           component: () => import('@/pages/simplePage/Overview')
  //         }
  //       ]
  //     },
  //     {
  //       path: '/m/d2/l2',
  //       meta: {
  //         title: 'keepAlive模块'
  //         // hideChildrenInMenu: true
  //       },
  //       component: RouterView,
  //       redirect: '/m/keepAlivePage/main',
  //       children: [
  //         {
  //           path: '/m/keepAlivePage/main',
  //           meta: {
  //             title: '主模块（列表页）'
  //           },
  //           component: RouterView,
  //           redirect: '/m/keepAlivePage/main/list',
  //           children: [
  //             {
  //               path: '/m/keepAlivePage/main/list',
  //               meta: {
  //                 title: '列表页',
  //                 keepAlive: true,
  //                 hidden: true
  //               },
  //               component: () => import('@/pages/keepAlivePage/main/List')
  //             },
  //             {
  //               path: '/m/keepAlivePage/main/detail',
  //               meta: {
  //                 title: '详情页',
  //                 hidden: true
  //               },
  //               component: () => import('@/pages/keepAlivePage/main/Detail')
  //             }
  //           ]
  //         },
  //         {
  //           path: '/m/keepAlivePage/overview',
  //           meta: {
  //             title: '其他模块'
  //           },
  //           component: () => import('@/pages/keepAlivePage/Overview')
  //         }
  //       ]
  //     }
  //   ]
  // }
]
export const constantRouterMap = [
  {
    path: '/',
    redirect: '/ops',
  },
  {
    path: '/user',
    component: RouterView,
    redirect: '/user/login',
    children: [
      {
        path: '/user/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/pages/user/Login'),
      },
    ],
  },
  {
    path: '/sso',
    component: () => import(/* webpackChunkName: "init" */ '@/pages/Sso'),
  },
  {
    meta: {
      title: '404',
    },
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/pages/exception/404'),
  },
  {
    meta: {
      title: '403',
    },
    path: '/403',
    component: () => import(/* webpackChunkName: "fail" */ '@/pages/exception/403'),
  },
  {
    path: '/ops',
    component: BlankLayout,
    redirect: '/ops/serviceList',
    children: [
      {
        meta: {
          title: '服务列表',
        },
        path: '/ops/serviceList',
        component: () => import('@/pages/scopes/main/ops/ServiceList'),
      },
      {
        path: '/ops/serviceDetail',
        component: () => import('@/pages/scopes/main/ops/ServiceDetail'),
      },
      {
        path: '/ops/serviceOp',
        component: () => import('@/pages/scopes/main/ops/ServiceOp'),
      },
      {
        path: '/ops/serviceOpDetail',
        component: () => import('@/pages/scopes/main/ops/ServiceOpDetail'),
      },
    ],
  },
]
