<template>
  <a-pagination
    class="queryView-pagination"
    :total="total"
    :current="current"
    :pageSize="pageSize"
    :showTotal="showTotalFn"
    :size="size"
    @change="onChange"
    @showSizeChange="onChange"
    showSizeChanger
    showQuickJumper
  />
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default() {
        return 'middle'
      },
    },
    showTotalFn: {
      type: Function,
      default: (total, range) => {
        return ``
      },
    },
    total: {
      type: Number,
      default: 0,
    },
    current: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {}
  },
  methods: {
    onChange(current, pageSize) {
      this.$emit('update:current', current)
      this.$emit('update:pageSize', pageSize)
      this.$emit('change', current, pageSize)
    },
  },
}
</script>
<style lang="less" scoped></style>
