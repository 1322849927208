<template>
  <a-layout class="bg-white" style="min-height: 100%">
    <a-layout-content class="relative p-8" style="">
      <transition name="page-transition">
        <KeepAliveRouterView />
      </transition>
    </a-layout-content>
  </a-layout>
</template>

<script>
import KeepAliveRouterView from '@/components/KeepAliveRouterView'

export default {
  name: 'BlankLayout',
  components: {
    KeepAliveRouterView,
  },
  data() {
    return {}
  },
}
</script>

<style lang="less">
/*
 * The following styles are auto-applied to elements with
 * transition="page-transition" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the page transition by editing
 * these styles.
 */
.page-transition-enter {
  opacity: 0;
}
.page-transition-enter-active {
  transition: opacity 0.8s;
}
</style>
