const _ = require('lodash')
let config = {}
const initConfig = function() {
  let cc = {}
  cc = _.merge({}, require('./base.config'), require('./env/prod.config'))

  if (process.env.VUE_APP_BUILD_ENV) {
    cc = _.merge({}, cc, require(`./env/${process.env.VUE_APP_BUILD_ENV}.config`))
  } else {
    if (process.env.NODE_ENV === 'development') {
      try {
        // 动态去搜寻local.config.js,如果有local.config.js，就使用local.config.js ,否则使用 /env/dev.config
        const localConfigContext = require.context('./', false, /local\.config\.js$/)
        const requireAll = context => context.keys().map(context)
        const localConfig = requireAll(localConfigContext)[0]
        const c = localConfig || require('./env/dev.config')
        cc = _.merge({}, cc, c)
      } catch (e) {}
    }
  }
  return cc
}
config = initConfig()
module.exports = config
