import { deviceEnquire, DEVICE_TYPE } from '@/utils/device'

const AppDeviceEnquire = {
  mounted() {
    const { $store } = this
    deviceEnquire((deviceType) => {
      console.log(deviceType)
      switch (deviceType) {
        case DEVICE_TYPE.DESKTOP:
          $store.commit('TOGGLE_DEVICE', 'desktop')
          break

        case DEVICE_TYPE.LAPTOP:
          $store.commit('TOGGLE_DEVICE', 'laptop')
          break

        case DEVICE_TYPE.TABLET:
          $store.commit('TOGGLE_DEVICE', 'tablet')
          break

        case DEVICE_TYPE.MOBILE:
        default:
          $store.commit('TOGGLE_DEVICE', 'mobile')
          break
      }
    })
  },
}
const filters = {
  filters: {
    text(value) {
      return value.replace(/<\/?.+?\/?>/g, '')
    },
  },
}
export { AppDeviceEnquire, filters }
